import React, { useState } from 'react';
import { useAccount } from '../../providers/authenticationProvider';
import { Notifications, UserMenu } from 'insify-remote-component-loader';
import { createBrowserHistory } from 'history';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'components/appRouting/AppContext';

export const UserMenuLoader = () => {
  const { userSectionCode } = useAppContext();
  const account = useAccount();
  const navigate = useNavigate();
  const history = createBrowserHistory();
  history.push = (to, state) => navigate(to, state);
  history.replace = (to, state) => navigate(to, { ...state, replace: true });

  const [notificationsIsOpen, setNotificationsIsOpen] = useState(false);

  const updatesConfig = {
    announcements: true,
    notifications: true
  };

  const links = [
    {
      href: '/manage/ManageHighRiskCountries',
      title: 'High Risk Countries',
    },
    {
      href: '/manage/ManageHighRiskInterests',
      title: 'High Risk Interests',
    },
    {
      href: '/announcements',
      title: 'Announcements',
    },
    {
      title: 'Notifications',
      onClick: () => setNotificationsIsOpen(true)
    },
    {
      title: 'Support',
      onClick: () => {
        window.open('https://instech.atlassian.net/servicedesk/customer/portal/10', '_blank');
      },
    },
  ];

  return (
    <>
      {userSectionCode &&
        <><UserMenu
          account={account}
          history={history}
          role={{
            displayName: userSectionCode,
            name: userSectionCode,
          }}
          portal={{ name: 'kyc', displayName: 'Know Your Customer' }}
          links={links}
          config={updatesConfig}
        />
        <Notifications
        account={account}
        history={history}
        isOpen={notificationsIsOpen}
        setIsOpen={setNotificationsIsOpen}
      /></>
      }
    </>
  );
};
