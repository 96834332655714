import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LeftArrow, Home, MenuDots, HighRisk, Load } from '@instech/icons';
import { ApproveChanges } from '../highRiskCountries/ApproveChanges';
import { WarningNotification } from '@instech/components';
import { BackgroundTaskProgressBar } from '../shared/BackgroundTaskProgressBar';
import {
  getAllInterests,
  getHighRiskInterestGroups,
  getHighRiskInterestCodes,
  updateHighRiskInterestCodes,
  updateHighRiskInterestGroups,
} from '../../services/getInterests';
import { InterestItem } from './InterestItem';
import { InterestMenu } from './InterestMenu';
import { areArraysDifferent } from '../../utils/areArraysDifferent';
import { useInterestGroupValidationTasks, useInterestCodeValidationTasks } from '../../services/useValidationTasks';
import { useAppContext } from '../appRouting/AppContext';

const StyledHighRisk = styled(HighRisk)`
  padding: 0 4px;
  color: ${(props) => props.theme.status.red};
`;
const StyledLoad = styled(Load)`
  height: 18px;
  margin-top: 8px;
`;
const StyledHome = styled(Home)`
  margin-right: 4px;
`;
const StyledMenuDots = styled(MenuDots)`
  transform: rotate(90deg);
  height: 16px;
  :hover {
    cursor: pointer;
  }
`;
const ErrorMessage = styled.div`
  color: red;
  margin-top: 8px;
  text-align: right;
`;
const Wrapper = styled.div`
  color: ${(props) => props.theme.marineBlue};
  margin: 32px 64px;
`;
const StyledLink = styled(Link)`
  font-size: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  :visited {
    color: ${(props) => props.theme.marineBlue};
  }
`;
const Description = styled.div``;
const Content = styled.div`
  margin: 0 64px;
`;
const GroupContainer = styled.div`
  background-color: ${(props) => (props.redBackground ? props.theme.background.red : props.theme.lightBlue)};
  margin: 16px 0;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  border-left: 4px solid ${(props) => (props.isHighRiskInterestGroup ? props.theme.lightRed : props.theme.marineBlue50)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;
const Title = styled.h2``;
const Subtitle = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.whiteBlue};
  padding: ${(props) => (props.isUserRoleAllowed ? '8px 0' : '8px')};
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
`;
const CheckboxMenu = styled.div`
  position: absolute;
  top: -32px;
  left: 16px;
  z-index: 99;
  font-weight: normal;
`;
const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
`;

export const ManageHighRiskInterests = () => {
  const { isUserRoleAllowed } = useAppContext();
  const [approveChanges, setApproveChanges] = useState(false);
  const [redBackground, setRedBackground] = useState(false);
  const [changedInterests, setChangedInterests] = useState([]);
  const [changedGroups, setChangedGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isError, setIsError] = useState(false);
  const [allInterests, setAllInterests] = useState(null);
  const [highRiskInterestCodes, setHighRiskInterestCodes] = useState(null);
  const [highRiskInterestGroups, setHighRiskInterestGroups] = useState(null);
  const [initialData, setInitialData] = useState({
    highRiskInterestCodes,
    highRiskInterestGroups,
  });
  const [openInterestMenuId, setOpenInterestMenuId] = useState('');
  const [shouldFetchInterestGroupValidationTasks, setShouldFetchInterestGroupValidationTasks] = useState(false);
  const [shouldFetchInterestCodeValidationTasks, setShouldFetchInterestCodeValidationTasks] = useState(false);

  const { data: interestGroupValidationTasks, mutate: mutateInterestGroupValidationTasks } =
    useInterestGroupValidationTasks(shouldFetchInterestGroupValidationTasks);

  const { data: interestCodeValidationTasks, mutate: mutateInterestCodeValidationTasks } =
    useInterestCodeValidationTasks(shouldFetchInterestCodeValidationTasks);

  useEffect(() => {
    isUserRoleAllowed && setShouldFetchInterestCodeValidationTasks(true);
    isUserRoleAllowed && setShouldFetchInterestGroupValidationTasks(true);
  }, [isUserRoleAllowed]);
  const clickOpenInterestMenu = (id) => {
    setOpenInterestMenuId(id);
  };
  useEffect(() => {
    const loadInterests = async () => {
      try {
        const allInterestsResult = await getAllInterests();
        const highRiskInterestGroupsResult = await getHighRiskInterestGroups();
        const highRiskInterestCodesResult = await getHighRiskInterestCodes();
        setAllInterests(allInterestsResult);
        !highRiskInterestGroups && setHighRiskInterestGroups(highRiskInterestGroupsResult);
        !highRiskInterestCodes && setHighRiskInterestCodes(highRiskInterestCodesResult);
        setInitialData({
          highRiskInterestGroups: highRiskInterestGroupsResult,
          highRiskInterestCodes: highRiskInterestCodesResult,
        });
      } catch (e) {
        console.log(e);
      }
    };
    loadInterests();
  }, [isApproved]);

  useEffect(() => {
    if (
      (highRiskInterestCodes &&
        initialData.highRiskInterestCodes &&
        areArraysDifferent(initialData.highRiskInterestCodes, highRiskInterestCodes, 'value')) ||
      (highRiskInterestGroups &&
        initialData.highRiskInterestGroups &&
        areArraysDifferent(initialData.highRiskInterestGroups, highRiskInterestGroups, 'value'))
    ) {
      setApproveChanges(true);
    } else {
      setApproveChanges(false);
    }
  }, [highRiskInterestCodes, highRiskInterestGroups, initialData]);

  const groupInterestsByGroupName = (array) =>
    Object.values(
      array.reduce((all, curr) => {
        const key = curr.group.name;
        (all[key] || (all[key] = [])).push(curr);
        return all;
      }, {})
    );

  const sortedInterests =
    allInterests &&
    allInterests.length > 0 &&
    allInterests.sort((a, b) =>
      a.group.name !== b.group.name
        ? a.group.name < b.group.name
          ? -1
          : 1
        : a.name !== b.name
        ? a.name < b.name
          ? -1
          : 1
        : 0
    );

  const groupedSortedInterests =
    sortedInterests && sortedInterests.length > 0 && groupInterestsByGroupName(sortedInterests);

  const isHighRiskGroup = (array) => {
    const groupName = array[0].group.name;
    if (highRiskInterestGroups?.some((el) => el.value === groupName)) return true;
    return array.some((el) => highRiskInterestCodes?.some((c) => c.value === el.name));
  };

  const groupsWithHighRisk = [];
  const groupsWithoutHighRisk = [];

  groupedSortedInterests &&
    groupedSortedInterests.length > 0 &&
    groupedSortedInterests.forEach((item) => {
      if (isHighRiskGroup(item)) {
        groupsWithHighRisk.push(item);
      } else {
        groupsWithoutHighRisk.push(item);
      }
    });

  const node = useRef(null);
  const handleClickOutside = (event) => {
    if (node.current && !node.current.contains(event.target)) {
      setOpenInterestMenuId('');
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  const changeInterestStatus = (name, isHighRisk) => {
    if (changedInterests.length > 0 && changedInterests.some((i) => i === name)) {
      setChangedInterests((prev) => prev.filter((p) => p !== name));
    } else {
      setChangedInterests((prev) => [...prev, name]);
    }
    isHighRisk && setHighRiskInterestCodes((prev) => prev?.filter((p) => p.value !== name));
    !isHighRisk && setHighRiskInterestCodes((prev) => [...prev, { value: name, type: 'HighRiskInterestCode' }]);
  };
  const changeGroupStatus = (name, isHighRisk) => {
    if (changedGroups.length > 0 && changedGroups.some((i) => i === name)) {
      setChangedGroups((prev) => prev.filter((p) => p !== name));
    } else {
      setChangedGroups((prev) => [...prev, name]);
    }
    if (isHighRisk) {
      setHighRiskInterestGroups((prev) => prev?.filter((p) => p.value !== name));
    } else {
      setHighRiskInterestGroups((prev) => [...prev, { value: name, type: 'HighRiskInterestGroup' }]);
    }
  };

  const handleChange = (name, isHighRisk, isGroup = false) => {
    setIsError(false);
    setIsApproved(false);
    setApproveChanges(true);
    setRedBackground(true);

    setTimeout(() => {
      if (isGroup) {
        changeGroupStatus(name, isHighRisk);
      } else {
        changeInterestStatus(name, isHighRisk);
      }
      setOpenInterestMenuId('');
    }, 100);
  };

  const handleCancel = () => {
    setIsError(false);
    setHighRiskInterestGroups(initialData.highRiskInterestGroups);
    setHighRiskInterestCodes(initialData.highRiskInterestCodes);
    setRedBackground(false);
    setChangedInterests([]);
    setChangedGroups([]);
  };

  const handleApprove = () => {
    setIsLoading(true);
    setRedBackground(false);
    setIsError(false);
    if (changedGroups.length > 0 && highRiskInterestGroups) {
      const data = highRiskInterestGroups.map((g) => g.value);
      updateHighRiskInterestGroups({ values: data })
        .then(() => {
          setRedBackground(false);
          setShouldFetchInterestGroupValidationTasks(true);
          setShouldFetchInterestCodeValidationTasks(true);
          setIsApproved(true);
          setChangedGroups([]);
        })
        .catch((error) => {
          console.log(error);
          setIsError(true);
          setRedBackground(false);
        });
    }
    if (changedInterests.length > 0 && highRiskInterestCodes) {
      const data = highRiskInterestCodes.map((g) => g.value);
      updateHighRiskInterestCodes({ values: data })
        .then(() => {
          setRedBackground(false);
          setShouldFetchInterestGroupValidationTasks(true);
          setShouldFetchInterestCodeValidationTasks(true);
          setIsApproved(true);
          setChangedInterests([]);
        })
        .catch((error) => {
          console.log(error);
          setIsError(true);
          setRedBackground(false);
        });
    }

    setTimeout(() => {
      setIsLoading(false);
      mutateInterestGroupValidationTasks();
      mutateInterestCodeValidationTasks();
    }, 2000);
  };

  const getInterestGroupName = (id) => allInterests && allInterests.filter((c) => c.group.id === id)[0].group.name;
  const getInterestCodeName = (id) => allInterests && allInterests.filter((c) => c.id === id)[0].name;
  const hasInterestCodeTask = (id) =>
    interestCodeValidationTasks && interestCodeValidationTasks.some((t) => t.data.entityId === id);
  const hasInterestGroupTask = (id) =>
    interestGroupValidationTasks && interestGroupValidationTasks.some((t) => t.data.entityId === id);

  return (
    <Wrapper>
      <StyledLink data-test-id="link-to-main-page" to="/">
        <LeftArrow />
        <div>
          <StyledHome />
          <span>Home</span>
        </div>
      </StyledLink>
      <Content>
        <Title>Interest Groups {isUserRoleAllowed && 'Management'}</Title>
        <Description>
          View all active interest groups, associated interests and {isUserRoleAllowed && 'manage'} high risk flag
          status.
        </Description>
        {!isApproved && approveChanges && (
          <ApproveChanges handleApprove={handleApprove} handleCancel={handleCancel} isError={isError} />
        )}
        {!isError &&
          ((interestGroupValidationTasks && interestGroupValidationTasks.length > 0) ||
            (interestCodeValidationTasks && interestCodeValidationTasks.length > 0)) && (
            <WarningNotification
              size="small"
              headingText="It will take some time to update high risk flags in the system. Updated items will be available to edit again in about  25-30min."
            />
          )}
        {isError && <ErrorMessage>Failed to update High Risk interests</ErrorMessage>}
        {isLoading && <StyledLoad />}
        {interestGroupValidationTasks &&
          interestGroupValidationTasks.length > 0 &&
          interestGroupValidationTasks.map((task, index) => {
            return (
              <BackgroundTaskProgressBar
                key={index}
                eventStreamTopic={task}
                titleText={getInterestGroupName(task.data.commandSubject.id)}
                mutate={mutateInterestGroupValidationTasks}
                eventName="InterestGroup"
              />
            );
          })}
        {interestCodeValidationTasks &&
          interestCodeValidationTasks.length > 0 &&
          interestCodeValidationTasks.map((task, index) => {
            return (
              <BackgroundTaskProgressBar
                key={index}
                eventStreamTopic={task}
                titleText={getInterestCodeName(task.data.commandSubject.id)}
                mutate={mutateInterestCodeValidationTasks}
                eventName="InterestCode"
              />
            );
          })}
        {groupsWithHighRisk &&
          groupsWithHighRisk.map((item, index) => {
            const isHighRiskInterestGroup = highRiskInterestGroups?.some((el) => el.value === item[0].group.name);
            const isGroupStatusChanged = changedGroups && changedGroups.includes(item[0].group.name);
            return (
              <GroupContainer
                key={index}
                isHighRiskInterestGroup={isHighRiskInterestGroup}
                redBackground={redBackground && isGroupStatusChanged}
                disabled={hasInterestGroupTask(item[0].group.id)}
              >
                <Subtitle isUserRoleAllowed={isUserRoleAllowed}>
                  {isUserRoleAllowed && (
                    <StyledMenuDots
                      data-test-id="interest-menu"
                      onClick={() => clickOpenInterestMenu(item[0].group.id)}
                    />
                  )}
                  <CheckboxMenu>
                    {openInterestMenuId === item[0].group.id && (
                      <InterestMenu
                        handleChange={() => handleChange(item[0].group.name, isHighRiskInterestGroup, true)}
                        node={node}
                        checked={isHighRiskInterestGroup}
                      />
                    )}
                  </CheckboxMenu>
                  {isHighRiskInterestGroup && <StyledHighRisk />}
                  <span>{item[0].group.name} Group</span>
                </Subtitle>
                <ItemsContainer>
                  {item.map((interest, index) => {
                    const isHighRiskInterestCode = highRiskInterestCodes?.some((el) => el.value === interest.name);
                    const isCodeStatusChanged = changedInterests && changedInterests.includes(interest.name);
                    return (
                      <InterestItem
                        key={index}
                        interest={interest}
                        handleChange={() => handleChange(interest.name, isHighRiskInterestCode)}
                        openInterestMenuId={openInterestMenuId}
                        node={node}
                        isHighRiskInterestCode={isHighRiskInterestCode}
                        clickOpenInterestMenu={clickOpenInterestMenu}
                        redBackground={redBackground}
                        isCodeStatusChanged={isCodeStatusChanged}
                        isGroupStatusChanged={isGroupStatusChanged}
                        isHighRiskInterestGroup={isHighRiskInterestGroup}
                        disabled={hasInterestCodeTask(interest.id)}
                        isUserRoleAllowed={isUserRoleAllowed}
                      />
                    );
                  })}
                </ItemsContainer>
              </GroupContainer>
            );
          })}
        {groupsWithoutHighRisk &&
          groupsWithoutHighRisk.map((item, index) => {
            const isHighRiskInterestGroup = highRiskInterestGroups?.some((el) => el.value === item[0].group.name);
            const isGroupStatusChanged = changedGroups && changedGroups.includes(item[0].group.name);
            return (
              <GroupContainer
                key={index}
                isHighRiskInterestGroup={isHighRiskInterestGroup}
                redBackground={redBackground && isGroupStatusChanged}
                disabled={hasInterestGroupTask(item[0].group.id)}
              >
                <Subtitle isUserRoleAllowed={isUserRoleAllowed}>
                  {isUserRoleAllowed && (
                    <StyledMenuDots
                      data-test-id="interest-menu"
                      onClick={() => clickOpenInterestMenu(item[0].group.id)}
                    />
                  )}
                  <CheckboxMenu>
                    {openInterestMenuId === item[0].group.id && (
                      <InterestMenu
                        handleChange={() => handleChange(item[0].group.name, isHighRiskInterestGroup, true)}
                        node={node}
                        checked={isHighRiskInterestGroup}
                      />
                    )}
                  </CheckboxMenu>
                  {isHighRiskInterestGroup && <StyledHighRisk />}
                  <span>{item[0].group.name} Group</span>
                </Subtitle>
                <ItemsContainer>
                  {item.map((interest, index) => {
                    const isHighRiskInterestCode = highRiskInterestCodes?.some((el) => el.value === interest.name);
                    const isCodeStatusChanged = changedInterests && changedInterests.includes(interest.name);
                    return (
                      <InterestItem
                        key={index}
                        interest={interest}
                        handleChange={() => handleChange(interest.name, isHighRiskInterestCode)}
                        openInterestMenuId={openInterestMenuId}
                        node={node}
                        isHighRiskInterestCode={isHighRiskInterestCode}
                        clickOpenInterestMenu={clickOpenInterestMenu}
                        redBackground={redBackground}
                        isCodeStatusChanged={isCodeStatusChanged}
                        isGroupStatusChanged={isGroupStatusChanged}
                        isHighRiskInterestGroup={isHighRiskInterestGroup}
                        disabled={hasInterestCodeTask(interest.id)}
                      />
                    );
                  })}
                </ItemsContainer>
              </GroupContainer>
            );
          })}
      </Content>
    </Wrapper>
  );
};
