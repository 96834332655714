import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { FleetOverviewPage } from '../fleetDetails/FleetOverviewPage';
import { EditPerson } from '../companyRoles/EditPerson';
import { NewCompany } from '../company/NewCompany';
import { NewCompanyForMultipleVessels } from '../fleetDetails/NewCompanyForMultipleVessels';
import { ManageHighRiskCountries } from '../highRiskCountries/ManageHighRiskCountries';
import { ManageHighRiskInterests } from '../highRiskInterests/ManageHighRiskInterests';
import { AcceptanceLogPageForFleet } from '../acceptanceLog/AcceptanceLogPageForFleet';
import { AcceptanceLogPageForOrganization } from '../acceptanceLog/AcceptanceLogPageForOrganization';
import { SingleCompanyPage } from '../projectTabs/SingleCompanyPage';
import { PreBindFleetsPage } from '../projectTabs/PreBindFleetsPage';
import { PostBindFleetsPage } from '../projectTabs/PostBindFleetsPage';
import { CompaniesPage } from '../projectTabs/CompaniesPage';
import { PersonsPage } from '../projectTabs/PersonsPage';
import { ProtectedRoute } from '../authentication/ProtectedRoute';
import { UpdatesPage } from '../header/UpdatesPage';
import { UnauthorizedPage } from '../authentication/UnauthorizedPage';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path={'/announcements'} element={<UpdatesPage />} />
        <Route path="preBindFleets" element={<PreBindFleetsPage />} />
        <Route path={'/'} exact element={<PostBindFleetsPage />} />
        <Route path="companies" element={<CompaniesPage />} />
        <Route exact path="persons" element={<PersonsPage />} />
        <Route path="fleets/:id" element={<FleetOverviewPage />} />
        <Route path="companies/:companyId" element={<SingleCompanyPage />} />
        <Route path={'persons/:clientId/:personId/edit'} element={<EditPerson />} />
        <Route path={'persons/:fleetId/:clientId/:personId/edit'} element={<EditPerson />} />
        <Route path={'persons/:fleetId/:clientId/:personId/edit'} element={<EditPerson />} />
        <Route path={'ubo/persons/:fleetId/:vesselId/:personId/edit'} element={<EditPerson />} />
        <Route path="/newcompany/:fleetId/:vesselId/:roleHeader" element={<NewCompany />} />
        <Route path="/newCompanyForMultipleVessels/:fleetId" element={<NewCompanyForMultipleVessels />} />
        <Route path="manage/ManageHighRiskCountries" element={<ManageHighRiskCountries />} />
        <Route path="manage/ManageHighRiskInterests" element={<ManageHighRiskInterests />} />
        <Route path="acceptanceLogForFleet/:fleetId" element={<AcceptanceLogPageForFleet />} />
        <Route path="acceptanceLogForOrganization/:organizationId/" element={<AcceptanceLogPageForOrganization />} />
        <Route
          path="acceptanceLogForOrganization/:organizationId/:fleetId/"
          element={<AcceptanceLogPageForOrganization />}
        />
      </Route>
      <Route path="user/unauthorized" element={<UnauthorizedPage />} />
    </Routes>
  );
};
