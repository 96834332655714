import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Warning, WarningFilled } from '@instech/icons';
import styled from 'styled-components';
import { AboutFleet } from './AboutFleet';
import { Company } from '../company/Company';
import { VesselsList } from '../vesselsList/VesselsList';
import { DownArrow, LeftArrow, Load } from '@instech/icons';
import { DefaultErrorMessage } from '../error/DefaultErrorMessage';
import { useEventStream } from '../../services/useEventStream';
import { useManualHighRisksForFleet } from '../../services/useManualHighRisks';
import { ApprovedNotification } from '../shared/ApprovedNotification';
import { QuotedFleetActions } from '../quotedFleets/QuotedFleetActions';
import { BeneficialOwnerWizard } from '../beneficialOwnerWizard/BeneficialOwnerWizard';
import { BackgroundTaskProgressBar } from '../shared/BackgroundTaskProgressBar';
import { useNavigate, Link } from 'react-router-dom';
import { CompanyProvider } from '../company/CompanyContext';
import { useFleetOverviewContext } from './FleetOverviewContext';
import { ActionsForQuotedFleetsContextProvider } from '../quotedFleets/ActionsForQuotedFleetsContext';
import { FleetOverviewHeader } from './FleetOverviewHeader';
import { AddManualHighRiskForFleetDialog } from './AddManualHighRiskForFleetDialog';
import {
  AggregateValidationFlags,
  ParseValidationFlags,
} from '../../utils/validations/functions';
import { RevalidateResyncButtons } from '../shared/RevalidateResyncButtons';
import { TooltipButtons } from '../shared/TooltipButtons';
import { useAppContext } from '../appRouting/AppContext';
import { subtractDate, subtractCurrentDate, guidelineInceptionDate } from 'utils/date';
import { FleetStatusTag } from './FleetStatusTag';
import { AcceptModalDialog } from "../shared/AcceptModalDialog";
import {
  proposeManualHighRiskAcceptance,
  reviewManualHighRiskAcceptance
} from "../../services/putAcceptanceReviews";
import { useManualHighRiskAcceptanceReviews } from "../../services/useAcceptanceReviews";
import { AcceptanceReviewStatus, AcceptanceType } from "../../utils/AcceptanceType";
import { useDebouncedFn } from "../../utils/useDebouncedFn";

const StyledLoad = styled(Load)`
  height: ${(props) => (props.height ? props.height : '20px')};
  align-self: center;
  margin: 32px 8px;
`;
const UpArrow = styled(DownArrow)`
  transform: rotate(180deg);
`;
const Wrapper = styled.div`
  color: ${(props) => props.theme.marineBlue};
`;
const FleetDetailsWrapper = styled.div`
  color: ${(props) => props.theme.marineBlue};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;
const FleetName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px 0;
  padding: 14px;
  border-radius: 2px;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow.boxShadowMain};

  :hover {
    cursor: pointer;
    background: ${(props) => props.theme.flatWhite};
  }
  > :nth-child(1) {
    font-weight: 600;
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
`;
const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.marineBlue75};
`;
const Subtitle = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
`;
const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
`;
const QuotedFleetInfo = styled.div`
  margin: 32px 0;
`;
const FleetNameAndButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  div > * {
    margin-right: 16px;
  }
`;
const LinkToAcceptanceLog = styled(Link)`
  text-transform: uppercase;
  color: ${(props) => props.theme.marineBlue75};
`;
const ProgressBarWrapper = styled.div`
  margin: 16px 0;
`;

const FleetActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const FleetDetails = () => {
  const {
    fleetId,
    fleet,
    hasAcceptanceLog,
    setIsAcceptanceLinkVisible,
    mutateFleetDetails,
    wizardValidationTasks,
    mutateVesselList,
    error,
    data,
    loading,
    showConfirmation,
    approvedNotificationText,
    isQuotedFleet,
    setShowConfirmation,
    clickAboutHandler,
    isAboutOpen,
    isBeneficialOwnerWizardOpen,
    mutateWizardValidationTasks,
    petis,
    mutatePETIs,
    isAcceptanceLinkVisible,
    locationState,
    setLocationState,
  } = useFleetOverviewContext();

  const { data: manualHighRisks, mutate: mutateManualHighRisks } = useManualHighRisksForFleet(fleetId);

  const inceptionDate = fleet?.inceptionDate;
  const noMissingInfo = fleet?.validations.acceptedMissingData == fleet?.validations.missingData;
  const isOverdueFleet =
    !noMissingInfo &&
    new Date(inceptionDate) >= new Date(guidelineInceptionDate) &&
    new Date(inceptionDate) <= new Date(subtractCurrentDate(30));
  const isFollowUpFleet =
    !noMissingInfo &&
    new Date(inceptionDate) >= new Date(subtractCurrentDate(29)) &&
    new Date(inceptionDate) <= new Date(subtractCurrentDate(15));
  const isMissingInfoFleet =
    !noMissingInfo &&
    (new Date(inceptionDate) <= new Date(subtractDate(1, guidelineInceptionDate))
      || new Date(inceptionDate) >= new Date(subtractCurrentDate(14)));

  const { isReviewer, isAdministrator, isDataAdministrator } = useAppContext();

  const [showAddManualHighRisk, setShowAddManualHighRisk] = useState(false);
  const [showProposeHighRiskAcceptanceModal, setShowProposeHighRiskAcceptanceModal] = useState(false);
  const [showReviewHighRiskAcceptanceModal, setShowReviewHighRiskAcceptanceModal] = useState(false);

  const hideAddManualHighRisk = useCallback(() => setShowAddManualHighRisk(false), []);

  const handleHighRiskAdded = useCallback(() => {
    mutateFleetDetails();
    mutateManualHighRisks();
    hideAddManualHighRisk();
  }, [mutateFleetDetails, hideAddManualHighRisk]);

  const { data: acceptanceReviews, mutate: mutateReviews} = useManualHighRiskAcceptanceReviews(fleet?.id);
  const pendingAcceptanceReview = acceptanceReviews?.find((review) =>
    review.acceptanceType === AcceptanceType.RiskLevel
    && review.status === AcceptanceReviewStatus.Pending);
  const hasPendingReview = !!pendingAcceptanceReview;

  const customActions = useMemo(() => [{
    text: "Set to High Risk",
    handler: setShowAddManualHighRisk,
    hidden: false,
    payload: true,
    icon: Warning,
  },{
    text: "Propose manual High Risk acceptance",
    handler: setShowProposeHighRiskAcceptanceModal,
    hidden: manualHighRisks?.every(x => x.accepted) || hasPendingReview,
    payload: true,
    icon: WarningFilled,
  },{
    text: "Review manual High Risk acceptance",
    handler: setShowReviewHighRiskAcceptanceModal,
    hidden: !isReviewer || !hasPendingReview,
    payload: true,
    icon: WarningFilled,
  }], [manualHighRisks, hasPendingReview, isReviewer]);

  const navigate = useNavigate();

  useEffect(() => {
    navigate(location.pathname, { state: locationState, replace: true });
  }, [locationState]);

  useEffect(() => {
    hasAcceptanceLog && setIsAcceptanceLinkVisible(hasAcceptanceLog);
  }, [hasAcceptanceLog]);

  const eventStreamTopic = `/Fleet/${fleetId}/${fleetId}/*`;
  const { onEvent, onConnect, onDisconnect, eventHandlerCleanup } = useEventStream(eventStreamTopic);

  onConnect(
    useCallback(() => {
      console.log('Connected to event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );

  onDisconnect(
    useCallback(() => {
      console.log('Disconnected from event stream for', eventStreamTopic);
    }, [eventStreamTopic])
  );

  // Equivalent PETI events usually come within ~150ms of each other
  const debouncedMutatePetis = useDebouncedFn(mutatePETIs, 500);
  // Events for different vessels in same fleet usually come within ~300ms of each other
  const debouncedMutateVesselList = useDebouncedFn(mutateVesselList, 750);

  useEffect(() => {
    onEvent((event) => {
      if (!event) {
        return;
      }
      if (event.type.endsWith('FleetRiskLevelChangedEvent') || event.type.endsWith('FleetMissingDataChangedEvent')) {
        mutateFleetDetails();
      } else if (event.type.endsWith('OrganizationRoleFlagsChangedEvent')) {
        // PETI will have an event for every object it is PETI for. Debounce to avoid large number of refreshes for large fleet
        debouncedMutatePetis();
      } else if (event.type.endsWith('InsuredObjectFlagsChangedEvent')) {
        // Every vessel may trigger events. Debounce to avoid large number of vessel list refreshes for large fleet
        debouncedMutateVesselList();
      } else if (event.type.endsWith('FleetSetToHighRiskEvent')) {
        mutateManualHighRisks();
      }
    });

    return eventHandlerCleanup;
  }, [mutatePETIs, mutateFleetDetails, mutateVesselList, eventStreamTopic]);

  useEffect(() => {
    wizardValidationTasks && mutateVesselList();
  }, [wizardValidationTasks]);

  if (error) return <DefaultErrorMessage />;
  if (!data || loading) return <StyledLoad />;

  const mutateVessels = () => {
    mutateVesselList && mutateVesselList();
    mutatePETIs && mutatePETIs();
  }

  const proposeHighRiskAcceptance = async (values) => {
    const command = {
      fleetId: fleet.id,
      data: {
        acceptancePeriod: {
          from: values.acceptanceDateFrom,
          to: values.acceptanceDateTo
        },
        comments: values.additionalComments,
        reviewerSubjectIds: values.reviewerSubjectIds?.map(x => x.value) ?? [],
        fleetId: fleetId
      },
      eTag: pendingAcceptanceReview?._etag || '*'
    };

    proposeManualHighRiskAcceptance(command)
      .then(() => {
        mutateFleetDetails();
        mutateReviews && mutateReviews();
        mutateManualHighRisks && mutateManualHighRisks();
      })
      .finally(() => setShowProposeHighRiskAcceptanceModal(false));
  }

  const reviewHighRiskAcceptance = async (values) => {
    const command = {
      fleetId: fleet.id,
      data: {
        acceptanceReviewStatus: values.acceptanceReviewStatus,
        reviewComments: values.additionalComments
      },
      eTag: pendingAcceptanceReview._etag
    };

    reviewManualHighRiskAcceptance(command)
      .then(() => {
        mutateFleetDetails();
        mutateReviews && mutateReviews();
        mutateManualHighRisks && mutateManualHighRisks();
      })
      .finally(() => setShowReviewHighRiskAcceptanceModal(false));
  }

  return (
    <Wrapper>
      {showConfirmation && <ApprovedNotification text={approvedNotificationText} />}
      <FleetNameAndButtonsWrapper>
        <Breadcrumbs>
          <Link data-test-id="link-to-fleets" to={{ pathname: isQuotedFleet ? `/preBindFleets` : `/` }}>
            <LeftArrow />
          </Link>
          <Item>
            <Title>/ Fleet</Title>
            <Subtitle>
              {fleet.name} ({fleet.insuranceYear})
            </Subtitle>
          </Item>
          {!isQuotedFleet && !inceptionDate && <FleetStatusTag highlight={'#C9CDD0'}>No Inception Date</FleetStatusTag>}
          {!isQuotedFleet && inceptionDate && isOverdueFleet && (
            <FleetStatusTag highlight={'#CEB2BA'}>Overdue</FleetStatusTag>
          )}
          {!isQuotedFleet && inceptionDate && isFollowUpFleet && (
            <FleetStatusTag highlight={'#E0CBCC'}>Follow Up</FleetStatusTag>
          )}
          {!isQuotedFleet && inceptionDate && isMissingInfoFleet && (
            <FleetStatusTag highlight={'#E3DECC'}>Missing Info</FleetStatusTag>
          )}
          {!isQuotedFleet && inceptionDate && noMissingInfo && (
            <FleetStatusTag highlight={'#C0DBD9'}>No Missing Info</FleetStatusTag>
          )}
          {isQuotedFleet && <FleetStatusTag highlight={'#B6DBE9'}>Pre-Bind</FleetStatusTag>}
        </Breadcrumbs>
        <FleetActions>
          <TooltipButtons actions={customActions} />
          {(isAdministrator || isDataAdministrator) && (
            <RevalidateResyncButtons entityId={fleetId} entityType={'Fleet'} entityPartitionKey={fleetId} />
          )}
          {isQuotedFleet && (
            <ActionsForQuotedFleetsContextProvider
              fleetId={fleetId}
              fleetName={fleet.name}
              insuranceYear={fleet.insuranceYear}
              setShowConfirmation={setShowConfirmation}
            >
              <QuotedFleetActions />
            </ActionsForQuotedFleetsContextProvider>
          )}
        </FleetActions>
      </FleetNameAndButtonsWrapper>
      <FleetDetailsWrapper>
        {isQuotedFleet && (
          <QuotedFleetInfo>
            This fleet is added to the KYC-portal as &quot;fleet in progress&quot;. Use <b>REMOVE</b> button to remove
            this fleet from &quot;fleets in progress&quot;, and <b>ACCEPT</b> to accept the fleet.
          </QuotedFleetInfo>
        )}
        <FleetName data-test-id="about-fleet" onClick={clickAboutHandler}>
          <FleetOverviewHeader item={fleet} />
          {isAboutOpen ? <UpArrow /> : <DownArrow />}
        </FleetName>
        {isBeneficialOwnerWizardOpen && <BeneficialOwnerWizard />}
        {isAboutOpen ? <AboutFleet /> : null}
        {fleet.clients.map((client) => (
          <CompanyProvider
            key={client.organization.id}
            client={client.organization}
            roleHeader="Client"
            fleetId={fleetId}
            mutateFleetDetails={mutateFleetDetails}
            mutateVesselList={mutateVesselList}
            locationState={locationState}
            setLocationState={setLocationState}
            isCompanyRole={true}
            validations={ParseValidationFlags(client.validations)}
            entityType={'OrganizationRole'}
          >
            <Company />
          </CompanyProvider>
        ))}
        {petis?.map((peti) => {
          const validations = AggregateValidationFlags(
            Object.values(peti.insuredObjectValidations).map((f) => ParseValidationFlags(f))
          );
          return (
            <CompanyProvider
              key={peti.role.organization.id}
              client={peti.role.organization}
              clientRoleType={peti.role.roleType}
              activePeriod={peti.role.activePeriod}
              covers={peti.interestCodes}
              fleetCovers={fleet.covers}
              fleetId={fleetId}
              roleId={peti.role.id}
              roleEtag={peti.role._etag}
              mutateFleetDetails={mutateFleetDetails}
              mutateVesselList={mutateVessels}
              roleSourceSystem="INS+"
              locationState={locationState}
              setLocationState={setLocationState}
              validations={validations}
              isCompanyRole={true}
              entityType={'OrganizationRole'}
            >
              <Company />
            </CompanyProvider>
          );
        })}
        <ButtonsWrapper>
          {isAcceptanceLinkVisible && (
            <LinkToAcceptanceLog
              to={{ pathname: `/acceptanceLogForFleet/${fleet.id}` }}
              style={{ alignSelf: 'center' }}
            >
              Link to acceptance log
            </LinkToAcceptanceLog>
          )}
        </ButtonsWrapper>
        <ProgressBarWrapper>
          {wizardValidationTasks &&
            wizardValidationTasks.length > 0 &&
            wizardValidationTasks.find((el) => el.data.commandSubject.id === fleet.id) &&
            wizardValidationTasks.map((task, index) => {
              return (
                <BackgroundTaskProgressBar
                  key={index}
                  eventStreamTopic={task}
                  eventName="Fleet"
                  titleText="Adding owners to objects"
                  mutate={mutateWizardValidationTasks}
                  mutateVesselList={mutateVesselList}
                  mutateVesselListForQuotedFleet={mutateVesselList}
                  forWizard
                />
              );
            })}
        </ProgressBarWrapper>

        <VesselsList />
      </FleetDetailsWrapper>
      {showAddManualHighRisk && (
        <AddManualHighRiskForFleetDialog
          fleet={fleet}
          onCancel={hideAddManualHighRisk}
          onComplete={handleHighRiskAdded}
        />
      )}
      {showProposeHighRiskAcceptanceModal && (
        <AcceptModalDialog
          text='Fill in to accept manual high risk for fleet'
          labelText='Reason for accepting high risk'
          highRisks={manualHighRisks.filter(x => !x.accepted)}
          cancelButtonText='Cancel'
          acceptButtonText='Propose HR acceptance'
          onCancel={() => setShowProposeHighRiskAcceptanceModal(false)}
          onAccept={(values) => proposeHighRiskAcceptance(values)}
        />
      )}
      {showReviewHighRiskAcceptanceModal && (
        <AcceptModalDialog
          text='Fill in to review high risk acceptance for person'
          labelText='Proposed reason for accepting high risk'
          reviewLabelText='Reason for accepting/rejecting proposal'
          highRisks={manualHighRisks.filter(x => !x.accepted)}
          pendingReview={pendingAcceptanceReview}
          cancelButtonText='Cancel'
          acceptButtonText='Accept high risk'
          rejectButtonText='Reject high risk'
          onCancel={() => setShowReviewHighRiskAcceptanceModal(false)}
          onAccept={(values) => reviewHighRiskAcceptance(values)}
        />
      )}
    </Wrapper>
  );
};
